#stage-clipped.myths{	
	padding-top: 11em;
	p{
		color: $lime-green;
		font-family: $font-PTSans;
	    font-size: 1.9em;
	    max-width: 30em;
	    margin: 0em auto 0;
	    line-height: 1.4em;
	}
}

#ui.myths {
	.act-alert-txt ul {
		text-align: left;
	}
}

