@keyframes fadeInBorder {
    0% {
        border-color: rgba(red, 0.1);
    }
    50% {
        border-color: rgba(red, 0.75);
    }
    100% {
        border-color: rgba(red, 0.1);
    }
}

#stage-clipped.unclip {
    -webkit-clip-path: none;
    clip-path: none;
}
.holder.a_triggers {
    h3 {
        font-size: 1.3em;
        width: 52.5rem;
        margin: 0.8em auto 0;
        color: #FFF;
        font-family: $font-Audiowide;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        text-align: left;
        opacity: 0;
        visibility: hidden;
    }
    p {
        color: $lime-green;
        font-family: $font-PTSans;
        font-size: 1.65em;
        line-height: 1.6em;
        text-align: left;
        width: 52.5rem;
        margin: 3.8em auto 0;
        .story-word {
            cursor: pointer;
        }
        .others {
            color: yellow;
        }
        .others * {
            text-decoration: line-through;
            cursor: unset;
        }
        .triggers {
            color: #FFF;
        }
        .triggers * {
            text-decoration: underline;
            cursor: unset;
        }
        .triggers.clickable, .others.clickable {
            color: $lime-green;
            background-color: rgba(0, 0, 0, 0);
            cursor: pointer;
        }
        .triggers.clickable * , .others.clickable * {
            text-decoration: none;
        }
    }
    .image-holder {
        visibility: hidden;
        opacity: 0;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
        }
        .hot-spot {
            position: absolute;
            @include border-radius(20%);
        }
        .hot-spot.found {
            border: 0.2rem solid $lime-green;
            @include box-shadow(0.5);
        }
        .hot-spot.reveal {
            border: 0.2rem solid rgba($lime-green, 0.1);
            -mozt-animation-name: fadeInBorder;
            -webkit-animation-name: fadeInBorder;
            animation-name: fadeInBorder;
            -moz-animation-duration: 1.6s;
            -webkit-animation-duration: 1.6s;
            animation-duration: 1.6s;
            -webkit-animation-timing-function: ease-in-out;
            -moz-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
            -moz-animation-iteration-count: infinite;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
        }
    }
}

#ui.a_triggers {
    .bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding:0 0.8em;
        opacity: 0;
    }
    .lower-txt{
        color: #FFF;
        font-family: $font-Audiowide;
        font-size: 2.5em;
        line-height: 0.9em;
        margin: 0 auto;
        opacity: 0.5;
        transition: 0.5s opacity;
        width: 10em;
        .trig-type:before {
            font-size: .6em;
            font-family: $font-Lato;
            color: $lime-green;
        }
        animation: blinkText 1s linear infinite;          
        @keyframes blinkText {
            50% {
                opacity: 0;
            }
        }
    }
    .lower-txt.found {
        opacity: 1;
        animation-name: none;
    }
    .lower-txt[data-type="person"] {
        text-align: left;
        .trig-type:before {
            content: "Person Trigger:"
        }
    }
    .lower-txt[data-type="place"] {
        text-align: center;
        .trig-type:before {
            content: "Place Trigger:"
        }
    }
    .lower-txt[data-type="thing"] {
        text-align: right;
        .trig-type:before {
            content: "Thing Trigger:"
        }
    }
    .wrd {
        text-transform: uppercase;
    }
    .review-images {
        img{
            margin: 0em 0.5em;
            margin-top: 1em;
            height: 7em;
        }
    }
    .act-alert-txt .instruct {
        text-align: left;
    }

}
.act-example{
    height: 225px;
    width: auto;
    margin-top: 0 !important;
}