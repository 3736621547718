.a_triggers_pong{
    .score {
        color: $light-blue;
        font-family: $font-Audiowide;
        font-size: 3em;
        margin: 4.1em auto;
        width: 2em;
        line-height: 0.8em;
        opacity: 0;
        display: flex;
        flex-direction: column;
        span {
            display: inline-block;
        }
        span:before {
            content: 'volume:';
            font-size: .45em;
            font-family: $font-Lato;
            color: $lime-green;
        }
    }
    .timer {
        opacity: 0;
        /* margin-left: 13em;
        margin-top: 0.25em;
        position: absolute; */
    }
    .countdown {
        color: $lime-green;
        font-size: 6em;
        font-family: $font-PTSans;
        font-weight: 700;
        margin-top: 1em;
        display: none;
    }
}
