.a_get_out,
.a_let_it_out1_wrap,
.a_let_it_out2_wrap,
.a_think_it_out_intro,
.a_shapematch2_intro,
.a_make_plan {
    .act-alert-txt h4 {
        font-size: 94%;
    }
    .img-wrap{
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        img {
            height: 400px;
            margin-top: 20px;
        }
    }
    .img-wrap.blur {
        filter: url(#blur-filter);
    }
    .emo-volume {
        color: $light-blue;
        font-family: $font-Audiowide;
        font-size: 3em;
        width: 2em;
        margin-left: -3.1em;
        left: 100%;
        top: 0;
        line-height: 0.8em;
        opacity: 0;
        visibility: hidden;
        display: flex;
        flex-direction: column;
        position: absolute;
        .vol {
            display: inline-block;
        }
        .vol:before {
            content: 'volume:';
            font-size: .45em;
            font-family: $font-Lato;
            color: $lime-green;
        }
    }
    .act-note-div.set-level {
        left: 39.8em;
        top: 9em;
    }
    .act-note-div.quiet {
        left: 7em;
        top: -3.2em;
    }
    .btn-wrap {
        position: relative;
        margin: 0 auto;
        .act-btn {
            margin: 1.4em 0.5em 0;
        }
    }
    .btn-holder {
        font-size: 40%;
        margin-bottom: 1em;
        button[data-active="1"] {
            color: $blue;
            border-color: $blue;
            background-color: $lime-green;
        }
    }
    .act-alert.emojis {
        label[for="user-bt-text"]{
            font-size: 70%;
            line-height: 1.5em;
            display: inline-block;
            text-align: left;
            width: 85%;
            margin-top: 0.6em;
        }
        label[for="user-text"]::before {
            content: 'Other: Type in any feeling/emotion you may have that is not listed above.';
        }
        label[for="user-bt-text"]::before {
            content: 'Other: Type in any body talk you may have that is not listed above.';
        }
    }
    // .act-alert.bodytalk {
    //     label[for="user-bt-text"]::before {
    //         content: 'Other: Type in any body talk you may have that is not listed above.';
    //     }
    // }
}

.a_let_it_out2_intro,
.a_shapematch2_intro {
    .act-alert-txt p {
        margin-bottom: 0;
        margin-top: 2em;
    }
    .btn-holder {
        font-size: 54%;
        margin-bottom: 1em;
        button[data-active="1"] {
            color: $blue;
            border-color: $blue;
            background-color: $lime-green;
        }
    }
}

#ui.a_think_it_out_intro {
    .strat-txt {
        margin-top: 2em;
        max-width: 80%;
    }
    .strat-txt::before {
        content: 'Chosen Thought(s): ';
    }
}

#ui.a_make_plan .bottom {
    .strat-txt {
        margin-top: 2em;
    }
    .strat-txt::before {
        content: 'Your Strategies: ';
    }
}

#stage.a_make_plan {
    .strat-txt {
        margin-top: -20.34em;
        position: absolute;
        left: 5%;
        width: 90%;
    }
}

