.a_label_feelings{	
    .text-holder{
        width: 39.5rem;
        text-align: left;
        margin-top: 5.5rem;
        margin-left: 9rem;
        float:left;
        p, ul {
            color: $lime-green;
            font-family: $font-PTSans;
            font-size: 1.3rem;
            line-height: 1.5rem;
        }
        li {
            margin-bottom: 1.1rem;
        }
    }
    #body-canvas{
            width: 20rem;
            height: 31.5rem;
            top: 7rem;
            left: 50.5rem;
            /* border: thin solid #FFF; */
            position: absolute;
    }
    .btn-holder {
        margin-top: 2rem;
        margin-left: 8.5rem;
        float:left;
        font-size: 85%;
        min-width: 31rem;
        text-align: left;
        .act-btn {
            margin: 0 0.5rem;
        }
    }
    .feedback .act-alert-txt p {
        text-align: left;
    }
}

