.behavior {
    p {
        color: $lime-green;
        font-family: $font-PTSans;
        font-size: 1.8em;
        line-height: 1.5em;
        text-align: left;
        max-width: 19em;
        position: absolute;
        margin: 0 auto 0 18em;
        top: 10.3em;
    }
    .behavior-img-wrap {
        width: 21.875em;
        height: 22.5em;
        position: absolute;
        margin-left: 8.8em;
        top: 12.5em;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .answers {
        color: #FFF;
        font-family: $font-Audiowide;
        font-size: 2.5em;
        line-height: 0.9em;
        position: absolute;
        opacity: 0.5;
        transition: 0.5s opacity;
        width: 10em;
        .emo:before {
            font-size: .5em;
            font-family: $font-Lato;
            color: $lime-green;
        }
        .wrds {
            margin-top: -.1em;
            display: inline-block;
        }
    }
    .answers.choice1 {
        text-align: left;
        left: 0.8em;
        .emo:before {
            content: 'emotion 1:';
        }
    }
    .answers.choice2 {
        left: 100%;
        margin-left: -10.8em;
        text-align: right;
        .emo:before {
            content: 'emotion 2:';
        }
    }
}

.choices-wrap {
    display:flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.6em;
    .behavior-word {
        color: $blue;
        font-family: $font-PTSans;
        font-weight: 700;
        font-size: 1.2em;
        padding: 0.2em 0.5em;
        border: thin solid $blue;
        background-color: $light-blue;
        width: 100%;
        margin: .3em .6em;
        display:inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .behavior-word div {
        display:inline-flex;
    }
    .behavior-word div:nth-child(2):before {
        content: ":";
    }
    .behavior-word div:nth-child(3) {
        font-size: 0.7em;
        line-height: 1.2em;
        text-align: left;
        width: 62%;
    }
    .behavior-word-on {
        color: $light-blue;
        background-color: $blue;
    }
}


