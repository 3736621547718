.act-alert.emojis {
    h3, h4{
        margin: 0 auto 0.25em;
    }
    .emojis-container {
        display: flex;
        flex-flow: row wrap;
        align-content: space-around;
        justify-content: center;
        width: 100%;
        height: auto;
        font-size: 62%;
        margin: 0 auto;
        .emoji {
            display: inline-flex;
            align-items: center;
            margin: 0.15em;
            flex-flow: column nowrap;
            border: none;
            background-color: transparent;
            width: 6.5em;
            .label {
                margin: -0.4em auto 0;
                font-weight: 700;
                font-size: 95%;
            }
        }
        .emoji.chosen {
            color: $light-blue;
            background-color: $blue;
        }
    }
}

.a_personalizing .act-alert.emojis {
    .emojis-container {
        font-size: 55%;
    }
    #user-text{
        margin: 0 auto;
        border: thin solid $blue;
        width: 85%;
    }
    label[for="user-text"]{
        font-size: 70%;
        line-height: 1.5em;
        display: inline-block;
        text-align: left;
        width: 85%;
        margin-top: 0.6em;
    }
    label[for="user-text"]::before {
        content: 'Optional: Type in any feelings you may have that are not listed above.\ASeparate feelings with commas.';
    }
}

.a_personalizing_2 .act-alert {
    .emo-word {
        color: $blue;
        margin: 0 auto;
    }
}

.a_personalizing_2 .act-alert.triggers {
    .triggers-wrap:first-of-type {
        border-top: thin solid $blue;
        padding-top: 1em;
    }
    .triggers-wrap:last-of-type {
        margin-bottom: 0;
    }
    .triggers-wrap {
        display: flex;
        flex-direction: row;
        width: 100%;
        font-size: 80%;
        margin: 1em auto;
        border-bottom: thin solid $blue;
        h3 {
            color: $blue;
            text-align: left;
            width: 16%;
            margin:0;
        }
        .triggers-container {
            width: 84%;
            display: flex;
            flex-flow: row wrap;
        }
        .trigger-btn {
            margin: 0.1em;
            border: thin solid;
            @include act-btn-colors($blue, $light-blue, $blue);
        }
        .trigger-btn.chosen {
            @include act-btn-colors($light-blue, $blue, $blue);
        }
        .input-wrap {
            width: 100%;
            margin: 0.5em 0 1em;
        }
        input {
            border: thin solid $blue;
            width: 73%;
            display: inline-flex;
        }
        label {
            color: $blue;
            width: 27%;
            display: inline-flex;
        }
    }
}

.emoji-sprite {
    width: 5em;
    height: 5em;
    display: inline-flex;
}
.emoji-sprite::after {
    content: '\00a0';
    display: inline-block;
    width: 5em;
    height: 5em;
    padding: 0;
    background-repeat: no-repeat;
    background-image: url(/images/emoji-sprite.png);
}
.angry::after {
    background-position: 0.1529051987767584% 0.12239902080783353%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.anxious::after {
    background-position: 0.1529051987767584% 20.0734394124847%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.ashamed::after {
    background-position: 0.1529051987767584% 40.024479804161565%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.bored::after {
    background-position: 0.1529051987767584% 59.975520195838435%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.confident::after {
    background-position: 0.1529051987767584% 79.9265605875153%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.confused::after {
    background-position: 0.1529051987767584% 99.87760097919217%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.curious::after {
    background-position: 25.07645259938838% 0.12239902080783353%;
    background-size: 503.7037037037037% 604.320987654321%;
}
.depressed::after {
    background-position: 25.07645259938838% 20.0734394124847%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.disappointed::after {
    background-position: 25.07645259938838% 40.024479804161565%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.disgusted::after {
    background-position: 25.07645259938838% 59.975520195838435%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.embarrassed::after {
    background-position: 25.07645259938838% 79.9265605875153%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.enraged::after {
    background-position: 25.07645259938838% 99.87760097919217%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.excited::after {
    background-position: 50% 0.12239902080783353%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.frustrated::after {
    background-position: 50% 20.0734394124847%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.grumpy::after {
    background-position: 50% 40.024479804161565%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.guilty::after {
    background-position: 50% 59.975520195838435%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.happy::after {
    background-position: 50% 79.9265605875153%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.hopeful::after {
    background-position: 50% 99.87760097919217%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.jealous::after {
    background-position: 74.92354740061162% 0.12239902080783353%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.lovestruck::after {
    background-position: 74.92354740061162% 20.0734394124847%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.mischievous::after {
    background-position: 74.92354740061162% 40.024479804161565%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.overwhelmed::after {
    background-position: 74.92354740061162% 59.975520195838435%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.proud::after {
    background-position: 74.92354740061162% 79.9265605875153%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.rejected::after {
    background-position: 74.92354740061162% 99.87760097919217%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.relaxed::after {
    background-position: 99.84709480122324% 0.12239902080783353%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.sad::after {
    background-position: 99.84709480122324% 20.0734394124847%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.scared::after {
    background-position: 99.84709480122324% 40.024479804161565%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.shy::after {
    background-position: 99.84709480122324% 59.975520195838435%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.stressed::after {
    background-position: 99.84709480122324% 79.9265605875153%;
    background-size: 503.7037037037037% 604.320987654321%;
}

.surprised::after {
    background-position: 99.84709480122324% 99.87760097919217%;
    background-size: 503.7037037037037% 604.320987654321%;
}
