.bodytalk{
	.points{
		color: $light-blue;
		font-family: $font-Audiowide;
        font-size: 2em;
	    width: 100%;
	    line-height: 0.96em;
	    margin: 6.7em auto;
		.pts:before{
			content:'points';
			font-size: .62em;
			font-family: $font-Lato;
			color: $lime-green;
		}
	}
	.countdown{
		color: #e0a20d;
	    font-size: 6em;
	    font-family: $font-PTSans;
	    font-weight: 700;
	    margin-top: 1em;
    	display: none;
	}
}

.brainstorm{
	input{
		font-family: $font-Lato;
		font-size: 1.5em;
		width: 9em;
		margin: -.15em auto 0;
		background-color: rgba(#FFF,.5);
	}
	.points{
		color: $light-blue;
		font-family: $font-Audiowide;
        font-size: 3em;
	    margin: 4.2em auto;
	    width: 1.8em;
	    line-height: 0.8em;
		span:before{
			content:'words ';
			font-size: .5em;
			font-family: $font-Lato;
			color: $lime-green;
		}
	}
	.right .act-btn{
		 margin-top: 11.6em;
	}
	.emoji-sprite {
		font-size: 150%;
	    background-color: rgba($maroon,.75);
	    border: .06em solid $light-blue;
	    position: relative;
	    margin-top: -14.3em;
	    float: right;
	    margin-right: 5.5em;
		overflow: hidden;
	}
	.type-display{
		color: #FFF;
		font-family: $font-Audiowide;
		font-size: 6em;
		text-align: center;
		text-transform: uppercase;
		background-color:rgba($light-blue,0.25);
		@include border-radius(4em);
		margin: 0.5em 2%;
		width: 96%;
	    opacity: 0.75;
	    position: absolute;
	    overflow: hidden;
	}
}

.zombie{
	position:fixed;
	height: 100%;
	width:100%;
	background: #FFF no-repeat url(/images/zombie.jpg);
	background-size: 100% 100%;
	display:none;
}

.emoji-table{
	width: 40em;
    height: auto;
    margin: -.3em -20.8em;
    padding: .8em;
    left: 50%;
	color: $blue;
	font-family: $font-Lato;
	cursor: pointer;
	background-color: rgba($light-blue,.92);
    position: absolute;
	@include border-radius(1em);
	overflow: hidden;
	.word{
		font-family: $font-PTSans;
		font-weight: 700;
		font-size: 1.2em;
		padding: .1em;
		border: thin solid $blue;
		background-color: $light-blue;
		width: 9em;
   		float: left;
    	margin: .3em .6em;
	}
	.on{
    		color: $light-blue;
    		background-color: $blue;
    	}
	p{
		width: 38.75em;
		margin-top: .6em;
		padding: .8em;
		text-align: left;
		float: left;
	}
	.act-btn{
		margin: 15em 0 0 -95%;
	}
}

