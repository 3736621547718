.login, .profile{
	#login-form, #create-form, #profile-form{
		font-family: $font-PTSans;
		text-align: left;
		width: 20em;
		margin: -1.9em 3.2em;
		button{
			float: right;
			margin-top: 0.4em;
		}
		input{
			width: 98%;
			color: #000;
			font-size: 1.5em;
		}
		input, select{
			margin: 0.4em auto;
			font-size: 1.3em;
		}
		select{
			width:100%;
			color: #000;
		}
	}
	h1{
		font-size: 1.8em;
		font-family: $font-Audiowide;
		text-transform: uppercase;
		white-space:nowrap;
		letter-spacing: 0.05em;
	}
	#login-form{
		float: left;
		color: $lime-green;
		margin-top: 3.35em;
		h1:after{
			content:" >>>";
			letter-spacing: 0em;
		}
	}
	#create-form{
		float: right;
		color: $light-blue;
		h1:before{
			content:"+ ";
			letter-spacing: 0em;
		}
	}
	#profile-form{
		float: right;
		color: $lime-green;
		margin-top: 2.2em;
	}
	.divider{
		color: #FFF;
		font-size: 1.8em;
		font-family: $font-Audiowide;
		text-transform: uppercase;
		letter-spacing: 0.05em;
		position: absolute;
		left: 45%;
    	top: 45%;
	}
	.profile-gfx{
		width: 20em;
		height: 20em;
		top: 3.7em;
    	left: 3.4em;
		position: absolute;
		h1{
			color: #FFF;
			margin-top: 0.53em;
		}
		div{
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			background: url(/images/profileBtn.svg) no-repeat transparent;
			background-size: contain;
			opacity:0.2;
		}
	}
	.act-alert{
		background-color: rgba($light-blue,0.97);
	}
}