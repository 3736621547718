.shapematch {
    .matched,
    .prev-score {
		color: $light-blue;
		font-family: $font-Audiowide;
		font-size: 3em;
		margin: 4.1em auto;
		width: 2.5em;
		line-height: 0.8em;
		opacity: 0;
		display: flex;
		flex-direction: column;
        span{
            display: inline-block;
        }
		span:before {
			font-family: $font-Lato;
            color: $lime-green;
		}
    }
    .matched span:before {
        content: 'matched:';
        font-size: .45em;
    }
    .prev-score span:before {
        content: 'score to beat:';
        font-size: .4em;
    }
    .timer{opacity:0};
    .countdown{
        /* color: #e0a20d; */
        color: $lime-green;
	    font-size: 6em;
	    font-family: $font-PTSans;
	    font-weight: 700;
	    margin-top: 1em;
    	display: none;
    }
    #dragger-group {
        opacity: 0;
    }
    #dragger-group rect{
        fill: rgba(0,0,0,0);
    }
    #dragger.shapes{
        fill:rgba(#e0a20d,0.65);
    }
    .matched #dragger.shapes {
        fill:$lime-green;
    }
    .not-matched #dragger.shapes {
        fill:red;
    }
    .shapes{
        fill:none;
        stroke: #FFF;
        stroke-width: 2px;
        stroke-linejoin: miter;
        stroke-miterlimit:10;
        stroke-linecap: square;
    }
    .svg-clipped.shapes{
        opacity: 0;
    }
    .reference{
        visibility: hidden;
    }
    .notif-wrap{
        /* height: 5rem;
        width: 16rem; */
        height: auto;
        width: auto;
        position: absolute;
        /* border: thin solid yellow; */
        .notification{
            height: 5em;
            width: 16rem;
            background-color: #FFF;
            @include border-radius(1rem);
            position: relative;
            visibility:hidden;
            opacity:0;
            overflow: hidden;
            .upper{
                height: 2rem;
                width: 16rem;
                color:#FFF;
                font-size:0.8rem;
                background-color: $blue;
                padding:0 0.5rem;
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                background-position: 0.7rem 0.4rem;
                background-size: 8%;
                background-repeat: no-repeat;
            }
            .upper.chat{
                background-image: url(/images/a_shapematch/chatsnap.svg);
            }
            .upper.gram{
                background-image: url(/images/a_shapematch/diregram.svg);
            }
            .upper.face{
                background-image: url(/images/a_shapematch/friendface.svg);
            }
            .upper::before{
                text-transform: uppercase;
                margin-left: 2rem;
            }
            .upper::after{
                content:"Now";
                opacity:0.6;
            }
            .upper.chat::before{
                content:"CHATSNAP";
            }
            .upper.gram::before{
                content:"DIREGRAM";
            }
            .upper.face::before{
                content:"FRIENDFACE";
            }
            .lower{
                height: 3rem;
                font-size:0.9rem;
                color:#000;
                display: inline-flex;
                align-content: center;
                align-items: center;
            }
        }
    }
    /* the 2 .notif-wrap divs must be the first 2 children of it's parent */
    .notif-wrap:nth-of-type(1) {
        top: 22rem;
        left: 9rem;
    }
    .notif-wrap:nth-of-type(2) {
        top: 22rem;
        left: 39rem;
    }
    .notif-wrap:nth-of-type(3) {
        top: -3.1rem;
        left: 42rem;
    }
    .act-note-div{
        top: 22.2rem;
        left: 20rem;
    }
    .btn-holder {
        font-size: 40%;
        margin-bottom: 1em;
        button[data-active="1"] {
            color: $blue;
            border-color: $blue;
            background-color: $lime-green;
        }
    }
    .act-alert.choose-strat {
        h4 {
            margin-bottom: 0.4em;
        }
        #user-text {
            width: 73%;
            border: thin solid $blue;
        }
        label {
            color: $blue;
            font-size: 87%;
        }
        label[for="user-text"]::before {
            content: 'Other: If you have another idea type it in.';
        }
    }
}