.strategies{
	.emo-volume {
		color: $light-blue;
		font-family: $font-Audiowide;
		font-size: 3em;
		margin: 4.1em auto;
		width: 2em;
		line-height: 0.8em;
		opacity: 0;
		display: flex;
		flex-direction: column;
		.vol {
			display: inline-block;
		}
		.vol:before {
			content: 'volume:';
			font-size: .45em;
			font-family: $font-Lato;
			color: $lime-green;
		}
	}
	.stars{
		top: 2.9em;
		width: 100%;
		opacity: 0.5;
		position: absolute;
		text-align: center;
		display: flex;
		justify-content: center;
		transition: all 0.6s ease-out;
		.star {
			height: 2.5em;
			width: 2.5em;
			margin: 0 0.25em;
			position: relative;
			display: inline-flex;
		}
	}
	.stars.move-down {
		font-size: 75%;
		top: 4.5rem;
	}
	.act-note-div.healthy, .act-note-div.unhealthy{
		top: 3em;
		font-size: 75%;
		top: 4.5rem;
	}
	.act-note-div.healthy{
		left: 10.3em;
		.act-note-text:after{
			content:' healthy strategies captured.'
		}
	}
	.act-note-div.unhealthy{
		left: 55.8em;
		.act-note-text:after{
			content:' unhealthy strategies captured.'
		}
	}
}