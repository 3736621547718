.act-alert.checkboxes {
    .act-alert-txt {
        text-align:left;
        font-size: 1.4em;
        margin: 0.35em auto;
        width: 85%;
        /* display: flex;
        flex-direction: row; */
        div{
            display: flex;
            margin-top:0.5em;
            margin-left:2em;
        }
        label, span, input[type="text"]{
            display: inline-flex;
            vertical-align: middle;
        }
        input[type="checkbox"] {
            visibility: hidden;
        }
        input[type="text"] {
            margin-left: 0.8em;
            font-size: 0.85em;
            border: 0.1em solid $blue;
        }
        input[type="text"][disabled] {
            opacity: 0.65;
        }
        span {
            margin-left: 0.6em;
            font-weight: 700;
            font-family: $font-PTSans;
        }
        label {
            width: 1.22em;
            height: 1.22em;
            margin-left: -0.65em;
            cursor: pointer;
            background: lighten($light-blue, 4);
            border-radius: 20%;
            border: 0.1em solid $blue;
            -moz-transition: background-color 0.2s;
            -webkit-transition: background-color 0.2s;
            transition: background-color 0.2s;
        }
        label:after {
            content: '';
            width: 1.22em;
            height: 1.22em;
            background: transparent no-repeat url(/images/btn-icon-sprite.png);
            background-size: percentage(924/132) 100%;
            background-position: 32.5% 0%;
            opacity: 0;
            display: block;
            border-radius: 20%;
            margin: -0.15em;
            -moz-transform: scale(0, 0);
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
            -moz-transition: 0.3s;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);
            -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);
            transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);
        }
        input[type="checkbox"]:checked, input[type="checkbox"]:checked+label:after {
            opacity: 1;
            -moz-transform: scale(1, 1);
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        input[type="checkbox"]:checked+label {
            background-color: $blue;
        }
    }
}