.a_let_it_out {
    .timer,
    .holder {
        opacity: 0
    }
    .countdown {
        /* color: #e0a20d; */
        color: $lime-green;
        font-size: 6em;
        font-family: $font-PTSans;
        font-weight: 700;
        margin-top: 1em;
        display: none;
    }
    .score {
        color: $light-blue;
        font-family: $font-Audiowide;
        font-size: 1.5rem;
        margin: 13.5rem auto;
        width: 7rem;
        line-height: 2rem;
        opacity: 0;
        display: flex;
        flex-direction: column;
        span {
            display: inline-block;
        }
        span:before {
            content: 'points:';
            font-size: 1.5rem;
            font-family: $font-Lato;
            color: $lime-green;
        }
    }
    .def-holder{
        top: 12em;
        left: 12em;
        position: absolute;
        border: 0.1em solid $light-blue;
        .def {
            width: 16em;
            height: 8em;
            background-color: rgba(0, 0, 0, 0.3);
            border-bottom: 0.1em solid $light-blue;
            display: flex;
            align-items: center;
            span {
                font-size: 1.2em;
                margin: 0 auto;
                font-family: $font-Lato;
                width: 85%;
                color: #FFF;
            }
        }
        .def:last-child {
            border: none;
        }
    }
    .word-holder {
        top: 8em;
        left: 56em;
        position: absolute;
        display: flex;
        flex-flow: column;
        .drag-word {
            color: #FFF;
            width: 9em;
            height: 9em;
            margin: 0.4em auto;
            background-color: rgba(0,0,0,0.5);
            border: 0.15em solid $light-blue;
            @include border-radius(50%);
            display: flex;
            align-items: center;
            visibility: hidden;
            span {
                font-family: $font-PTSans;
                font-size: 1.4em;
                /* font-weight: 700; */
                width: 90%;
                margin: 0 auto;
            }
        }
        .drag-word.correct {
            color: $green;
            font-weight: 700;
            background-color: rgba(255, 255, 255, 1);
        }
        .drag-word.incorrect {
            color: $maroon;
            font-weight: 700;
            background-color: rgba(255, 255, 255, 1);
        }
    }
    #bonus-msg {
        color: #FFF;
        font-size: 4em;
        font-family: $font-PTSans;
        font-weight: 700;
        text-align: center;
        width: 60%;
        margin: 1em 20% 0;
        visibility: hidden;
        opacity: 0;
        position: absolute;
    }
    .blinker{
        animation: blinkTxt 1s linear infinite;
        @keyframes blinkTxt {
            50% {
                opacity: 0;
            }
        }
    }
}