$hightlight-green: lighten(desaturate($lime-green, 20%), 35%);

.controls {
    width: 10em;
    height: 10em;
    background-color: darken($maroon, 10);
    border: 2px solid $light-blue;
    @include border-radius(50%);
    margin: -4em auto;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    .joystick {
        width: 5em;
        height: 5em;
        background: radial-gradient(circle at 50% 120%, $light-green, $lime-green 10%, $green 80%, darken($green, 13%) 100%);
        @include border-radius(50%);
        display: inline-block;
        position: absolute;
        overflow: hidden;
        -webkit-box-shadow: 0px 6px 5px 6px rgba(0, 0, 0, 0.7);
        -moz-box-shadow: 0px 6px 5px 6px rgba(0, 0, 0, 0.7);
        box-shadow: 0px 6px 5px 6px rgba(0, 0, 0, 0.7);
        z-index: 2;
    }
    .joystick:before {
        content: "";
        position: absolute;
        top: 1%;
        left: 5%;
        width: 4.5em;
        height: 4.5em;
        @include border-radius(50%);
        background: radial-gradient(circle at 50% 0px, $light-green, rgba($light-green, 0) 58%);
        -webkit-filter: blur(5px);
        filter: blur(5px);
        z-index: 3;
    }
}

.controls:before {
    content: "";
    width: 2em;
    height: 2em;
    background: radial-gradient(circle at 50% 0%, lighten(desaturate($lime-green, 20%), 20%), rgb(56, 68, 56) 50%, #000);
    border: 3px solid $lime-green;
    @include border-radius(50%);
    position: absolute;
}

.controls:after {
    content: "";
    width: 2em;
    height: 2em;
    border: 1px solid #000;
    @include border-radius(50%);
    position: absolute;
}