a[disabled], button[disabled]{
	opacity: .4;
	cursor: not-allowed;
	text-decoration: line-through;
	pointer-events: none;
}

#home-btn, #profile-btn{
	margin: 0.8em 0 0 0.8em;
	width: 2.1em;
	height:2.1em;
	float: left;
	cursor: pointer;
}

#home-btn{
	background: url(/images/homeBtn.svg) no-repeat transparent;
	background-size: contain;
}

#profile-btn{
	background: url(/images/profileBtn.svg) no-repeat transparent;
	background-size: contain;
}

#un-btn{
	-webkit-font-smoothing: antialiased;
	font-family: $font-Lato;
	font-size: 1em;
	color: $pink;
	text-decoration: none;
	text-overflow: ellipsis;
	max-width: 7.3em;
    overflow: hidden;
    white-space: nowrap;
	margin-top: 1.05em;
    margin-left: 0.3em;
    float: left;
    cursor: pointer;
}

#un-btn:before{
	content:':: ';
}

#logout-btn{
	margin: 0.8em 0.8em 0 0;
	width: 5.9em;
    height: 1.9em;
	background: url(/images/logOutBtn.svg) no-repeat transparent;
	background-size: contain;
	float: right;
	cursor: pointer;
}

#main-btn{
	width: 214px;
	height: 65px;
	margin: 0 auto;
	color: $lime-green;
	text-align: center;
	text-transform: uppercase;
	font-family: 'Audiowide', cursive;
	font-size: 32px;
	border: none;
	border-top: 3px solid $lime-green;
	border-bottom: 3px solid $lime-green;
	background-color: adjust-color($lime-green, $alpha: -0.86);
	overflow: hidden;
}

#main-btn[data-type="timer"]:before{
	content:'START';
}

#main-btn[data-type="pause"]:before{
	content:'PAUSE';
}

#main-btn[data-type="skip"]:before{
	content:'SKIP';
}

#main-btn[data-type="next"]:before{
	content:'NEXT';
}

#main-btn[data-type="continue"]:before{
	content:'REPLAY';
}

#main-btn[data-type="create"]:before{
	content:'CREATE ACCOUNT';
	font-size: 0.8em;
}

.login #main-btn{
	line-height: 0.75em;
}

.home #main-btn:before{
	content:'';
}

#continue-btn{
	width: 16em;
    height: 16em;
    margin: 5.4em auto 0 auto;
	background: url(/images/continueBtn.svg) no-repeat;
	background-size: contain;
	border:none;
}

.play-btn{
	width: 195px;
    height: 190px;
    margin: 122px auto 0 auto;
	background: url(/images/play_btn.svg) no-repeat;
	background-size: contain;
}

.replay-btn{
	background: url(/images/replayBtn.svg) no-repeat;
}

.act-btn{
	font-family: $font-Lato;
	font-weight: 400;
	font-size: 1.75em;
	padding: .3em .7em .39em .7em;
	border-width: .14em;
	border-style: solid;
	@include border-radius(.56em);
	cursor: pointer;
}

@mixin act-btn-colors($fnt, $bg, $brd) {
	color: $fnt;
	background-color: $bg;
	border-color: $brd;
}

.act-btn-blue, .act-btn-blue .btn-icon-sprite{
	@include act-btn-colors($light-blue, $blue, $light-blue);
}

.act-btn-maroon, .act-btn-maroon .btn-icon-sprite{
	@include act-btn-colors($pink, $maroon, $pink);
}

.act-btn-green, .act-btn-green .btn-icon-sprite{
	@include act-btn-colors($light-green, $green, $light-green);
}

.act-btn-pink, .act-btn-pink .btn-icon-sprite{
	@include act-btn-colors($pink, transparent, $pink);
	font-family: $font-PTSans;
	font-weight: 300;
	font-size: 1.3em;
	border-width: .07em;
	@include border-radius(1em);
}

.act-btn-yellow, .act-btn-yellow .btn-icon-sprite{
	@include act-btn-colors(#ffdb6e, transparent, #ffdb6e);
	font-family: $font-PTSans;
	font-weight: 300;
	font-size: 1.3em;
	border-width: .07em;
	@include border-radius(1em);
}

.btn-icon-sprite{
	float: left;
	width: 1.25em;
	height: 1.25em;
	margin-right: 0.3em;
	/* display:inline-block; */
	overflow:hidden;
	background-repeat: no-repeat;
	background-image:url(/images/btn-icon-sprite.png);
	background-size: percentage(924/132) 100%;
	background-position: 100% 0%; //default is next
	opacity: 0.32;
	/* border: thin white solid;  */ 
}

.btn-icon-sprite.add {background-position: 0% 0%}
.btn-icon-sprite.back {background-position: 16% 0%}
.btn-icon-sprite.check {background-position: 33.5% 0%}
.btn-icon-sprite.close {background-position: 50% 0%}
.btn-icon-sprite.info {background-position: 66.5% 0%}
.btn-icon-sprite.minus {background-position: 83.5% 0%}
.btn-icon-sprite.next {background-position: 100% 0%}


