#display-text{
	font-size: 23px;
	font-family: "Audiowide";
	fill: $light-blue;
	letter-spacing: 1px;
	.rank{
		text-transform: uppercase;
		text-anchor: end;
	}
}
#display{
	.display-fill{
		fill:$maroon;
		opacity:0.35; 
	}
	.display-line{
		fill: none;
		stroke: $light-blue;
		stroke-width: 1;
		stroke-miterlimit: 10; 
	}
}

#logo{
	.green-fill{
		fill: $lime-green;
	}
	.white-fill{
		fill: #FFF;
	}
	.green-stroke{
		fill: none;
		stroke: $lime-green;
		stroke-miterlimit: 10;
		stroke-width: 3;
	}
	.round-stroke{
		stroke-width: 2.5;
		stroke-linecap: round;
	}
}

#display-badge{
	opacity: 0.5;
	.badge-number{
		font-size: 23px;
        font-family: "Audiowide";
        fill: $maroon;
        text-anchor: middle;
	}
	.badge-fill{
        fill: #9cf;
	}
}
.avatar-bg {fill: url(#radial-gradient);}
.svg-clipped{clip-path:url(#grid-clip);}
.grid-line{
	fill:none;
	stroke:$maroon;
	stroke-miterlimit:10;
}

/* modex svg */
.modex-svg{
	.number{fill:#99CCFF; opacity:0.25}
	.status-1{fill:#FFF; opacity:1}
	.status-2{opacity:1}
	.st1{fill:#99CCFF;}
	.st2{opacity:0.5;}
	.st3{fill:#FF99FF;}
	/* .stage1-btn{
		cursor:	pointer;
		.st0{fill:#660033;}
	}
	.stage2-btn{
		cursor:	pointer;
		.st0{fill:#520A3D;}
	}

	*/
	.stage1-btn{
		cursor:	pointer;
		.st0{fill:#3D1447;}
	}  
	.stage2-btn{
		cursor:	pointer;
		.st0{fill:#291F52;}
	}
	.stage3-btn{
		cursor:	pointer;
		.st0{fill:#14295C;}
	}
	.stage4-btn{
		cursor:	pointer;
		.st0{fill:#003366;}
	}
	#target{
		.cls-1, .cls-3 {
	        opacity: 0.35;
	      }
	      .cls-2 {
	        fill: #fff;
	        opacity: 0.5;
	      }
	      .cls-3 {
	        fill: $lime-green;
	      }
	      .cls-4 {
	        opacity: 0.55;
	      }
	      .cls-5 {
	        fill: none;
	        stroke: $lime-green;
	        stroke-miterlimit: 10;
	        stroke-width: 2px;
	      }
	}
}