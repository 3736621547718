.scenarios {
	.arrowsContainer {
		float: left;
		height: 320px;
		margin-top: 140px;
		width: 90px;			
	}
	
	.choicesContainer {
		float: left;
		height: 100%;
		margin-top: 96px;
		width: 30%;			
	}
	.choice{
		height: 18%;
		margin-bottom: 25px;	
		width: 90%;	
		background-color: rgba(153, 204, 255, 0.82);
        @include border-radius(1em);
        font-family: $font-PTSans;	
        font-size: 1.3em;
        font-weight: 700;
        cursor: pointer;
		padding: 12px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	/* .descContainer {
		min-height: 400px;
		background-color:rgba(0, 0, 0, 0.6);
		padding-top: 5%;
    	font-size: 1.4em;		
    	width: 540px;    	 
    	color: #fff;   
    	position: absolute;
    	top: 173px;
    	left: 159px;
	} */
	.play-btn{
		margin: -18em auto 0 45%;
		cursor: pointer;
	}	
	.scenario {
		float: left;
		height: 100%;
		width: 55%;
		cursor: pointer;
		img {
			margin-left: 113px;
			margin-top: 96px;
		}		
	}
	.scenario.center {
		float: none;
		width: 100%;
		img {
			margin-left: 0;
			margin-top: 84px;
		}
		.play-btn{
			margin: -18em auto 0 auto;
		}	
	}
	svg .line {
    	fill: none;
    	stroke: #21d921;
    	stroke-miterlimit: 10;
    	stroke-width: 8px;
	}
	svg .head {
		fill: #21d921;
	}
	.countdown {		
		color: #FFF;
	    font-family: $font-Audiowide;
	    font-size: 4.5em;
	    margin: -.1em auto;    
	}
	.countdown span {
	    width: 0.9em;
	    margin: 0;
	    display: inline-block;
	}
	.countdown .colon {
	    	width: 0.2em;
    		margin-right: 0.05em;
    }
    .feelings{
		font-family: $font-Audiowide;
        font-size: 2.5em;
	    line-height: 0.75em;
	    position: absolute;
	    opacity:0.5;
	    width: auto;
	    text-align: left;
		color: $light-blue;
		margin: .4em 0 0 2.7em;
		opacity:0;
		.emo:before{
			content:'emotion(s):';
			font-size: .5em;
			font-family: $font-Lato;
			color: $lime-green;
		}
		.wrds{
			font-size: .72em;
			text-transform: uppercase;
			margin-top: -.2em;
			display: inline-block;
		}
	}
	.emo-volume{
		top: .4em;
		font-family: $font-Audiowide;
        font-size: 2.5em;
	    line-height: 0.75em;
	    position: absolute;
	    opacity:0;
	    width: 1.6em;
	    color: $light-blue;
		left: 0.5em;
		.vol:before{
			content:'vol:';
			font-size: .5em;
			font-family: $font-Lato;
			color: $lime-green;
		}
		.num{
			margin-top: 0.1em;
			display: inline-block;
		}

	}
}

.scenarios1 {
	.choice{
		margin-bottom: 8em;	
	}
}
