/* normalize */
@import "normalize.min";
/* vars */
$font-PTSans: 'PT Sans', sans-serif;
$font-Lato: 'Lato', sans-serif;
$font-Audiowide: 'Audiowide', sans-serif;
$lime-green: rgb(33,217,33);
$pink: rgb(255,153,255);
$light-blue: rgb(153,204,255);
$maroon: rgb(102,0,51);
$blue: rgb(0,51,102);
$green: darken($lime-green,22%);
$light-green: lighten(desaturate($lime-green, 35%), 45%);

/* mixins */
@mixin user-select-none() {
	-moz-user-select: none; 
    -khtml-user-select: none; 
    -webkit-user-select: none; 
    -o-user-select: none;
    user-select: none;
}

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin border-bottom-radius($radius) {
	-moz-border-radius-bottomright: $radius;
	-webkit-border-bottom-right-radius: $radius;
	border-bottom-right-radius: $radius;
	-moz-border-radius-bottomleft: $radius;
	-webkit-border-bottom-left-radius: $radius;
	border-bottom-left-radius: $radius;
}

@mixin border-top-radius($radius) {
	-moz-border-radius-topright: $radius;
	-webkit-border-top-right-radius: $radius;
	border-top-right-radius: $radius;
	-moz-border-radius-topleft: $radius;
	-webkit-border-top-left-radius: $radius;
	border-top-left-radius: $radius;
}

@mixin bg-image-svg($name) {
	/* for browsers that DON'T support svg */
	background: url(#{$name}.png) no-repeat;
	/* for browsers that DO support svg */
	background: url(/images/nada.png), url(#{$name}.svg) no-repeat;
}

@mixin box-shadow($opacity) {
	-webkit-box-shadow: 0px 0px 1.25em 0.125em rgba(0,0,0,$opacity);
	-moz-box-shadow: 0px 0px 1.25em 0.125em rgba(0,0,0,$opacity);
	box-shadow: 0px 0px 1.25em 0.125em rgba(0,0,0,$opacity);
}

/* main scss */
 * {
	 box-sizing: border-box;
 }
body{
	background-color: #000;
	@include user-select-none();
	overflow: hidden;
}
input[disabled]{
	opacity: .4;
	cursor: not-allowed;
	pointer-events: none;
}

@-ms-viewport{
  width: device-width;
}

#main-wrapper{
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	opacity: 0;
}
h3.badge-name{
	color:$blue;
	margin-bottom: -0.5em;
    text-decoration: underline;
    text-decoration-style: double;
}
.bg{
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;
}

.bg[data-type="stars"]{
	background-image: url(/images/stars_bg.jpg);
}

.bg[data-type="planet"]{
	background-image: url(/images/stars_bg_planet.jpg);
}

.bg[data-type="grid"]{
	background-image: url(/images/stars_bg_planet_grid.jpg);
}

.bg[data-type="grid-no-planet"] {
	background-image: url(/images/stars_bg_grid.jpg);
}

.bg[data-type="planet-solo"] {
	background-image: url(/images/green_planet.png);
}

#stage{
	text-align: center;
	padding-top: 78px;
}
#display{
	pointer-events: none;
}

#ui-wrapper{
	pointer-events: none;
}

#ui{
	text-align: center;
	pointer-events: none;
	.top{
		width: 100%;
		height: 100px;
		pointer-events: all;
	}
	.center{
		width: 1020px;
    	height: 446px;
    	margin: 40px 130px 20px;
    	position: absolute;
    	z-index: 900;
    	>* {
    		pointer-events: all;
        }
        *:disabled, *[disabled] {
            pointer-events: none;
        }
	}
	.bottom{
		width: 100%;
    	height: 150px;
    	margin: 510px 0;
    	position: absolute;
		pointer-events: all;
	}
	.right, .left{
		width: 130px;
    	height: 510px;
    	pointer-events: all;
	}
	.right{
		float: right;
	}
	.left{
		float: left;
	}
}

.holder{
    width:1190px;
    height:568px;
    margin: 0 auto;
}

.holder2{
    margin-top: -568px;
}
/* this is what stage-clipped should be, but after Chrome update 55.0.2873.0 for Windows, Linux and  55.0.2873.4 for Mac this css is broken */
/* #video, div#stage-clipped{
	-webkit-clip-path:url(#grid-clip);
    clip-path:url(#grid-clip);
}

canvas#stage-clipped{
	-webkit-clip-path:url(#canvas-clip);
    clip-path:url(#canvas-clip);
} */

/* 
	changed css for Chrome update 55.0.2873.0 for Windows, Linux and  55.0.2873.4 for Mac
	canvas#stage-clipped on chrome windows gets set to {-webkit-clip-path: none; clip-path: none} via js. see /js/session4/a_strategies.js
	fuck you google!

	update: Windows Chrome update70.0.3538.67 clip-path on video element make video playback & user interactions super sluggish
	turning off clip-path on video for now
	fuck you again google! Or is is 'fuck me'?

*/
/* #video{
	-webkit-clip-path:url(#grid-clip);
    clip-path:url(#grid-clip);
} */

#stage-clipped{
	-webkit-clip-path:url(#canvas-clip);
    clip-path:url(#canvas-clip);
}

.offstage {
	display: none;
	height: 0;
	width: 0;
	margin-left: -4000px;
}

.vert-align{
  	top: 50%;
  	-webkit-transform: translateY(-50%);
  	-ms-transform: translateY(-50%);
  	transform: translateY(-50%);
  	position: absolute;
  	overflow: hidden;
}

.top-align{
	top: 0;
  	transform: none;
}

.height-me{
	width: 100%;
	height: auto;
}

.scale-me{
	top: 0;
	left: 0;
	width: 1280px;
	height: 720px;
	position: absolute;
	// z-index: -20 !important;
}

.vid-hotspot{
    width: 1020px;
    height: 446px;
    margin: 0 auto;
    position: absolute;
    cursor: pointer;
}

.box-shadow{
	@include box-shadow(0.8);
}

#avatar-head{
	width: 160px;
	height: 145px;
    left: 258px;
    top: 17px;
	background-image: url(/images/heads.png);
    background-repeat: no-repeat;
    background-size: 403%;
    background-position: 200px 200px;
	position: absolute;
	display:inline-block;
	overflow:hidden; 
}
#avatar-head[data-type="avatar-1"]{
	background-position: -0.5px -0.5px;
}
#avatar-head[data-type="avatar-2"]{
	background-position: -161px 0px;
}
#avatar-head[data-type="avatar-3"]{
	background-position: -322px 0px;
}
#avatar-head[data-type="avatar-4"]{
	background-position: -483px 0px;
}
.avatar-loader{
	display: none;
}
.avatar-loader.svg{
	background-image: url(/images/avatar2.svg), url(/images/avatar1.svg), url(/images/avatar2.svg), url(/images/avatar1.svg);
}
.avatar-loader.png{
	background-image: url(/images/heads.png);
}
.star{
	height: 2.0625em;
	width: 2.0625em;
	background: url(/images/star.svg) no-repeat;
	background-size: contain;
}
.timer{
	color: #FFF;
    font-family: "Audiowide", sans-serif;
    font-size: 4.5em;
    margin: -.1em auto;
    span{
    	width: 0.9em;
    	margin: 0;
    	display: inline-block;
    }
    .colon{
    	width: 0.2em;
		margin-right: 0.05em;
    }
}

.home{ 
	.split{
		font-family: $font-Audiowide;
		color: #FFF;
		font-size: 3em;
		margin-top: 1.3em;
	}
	.act-alert{
		width: 48em;
		margin-left:-26em;
		background-color: rgba($light-blue,0.88);
		p{line-height: 2.2em;}
		.btns{
			a{
				font-family: $font-PTSans;
				font-weight: 700;
				font-size: 1.3em;
				text-decoration: none;
				color: $blue;
				background-color: $light-blue;
				border: 0.1em solid $blue;
				margin: 0.5em;
				padding: 0.2em 0.4em;
				display: inline-block;
			}
			a.challenge{
			background-color: $lime-green;
			}
		}
	}
}

.strat-txt {
	color: $light-blue;
	font-family: $font-Lato;
	font-size: 1.45em;
	opacity: 0;
	max-width: 90%;
	margin: 0 auto;
}

.strat-txt::before {
	content: 'Chosen Strategy: ';
	color: $lime-green;
}

/* other sheets */
@import "svg";
@import "buttons";
@import "components";
@import "alert_checkboxes";
@import "joystick";
@import "login_profile";
@import "a_s1_intro";
@import "a_avatar";
@import "a_shapematch";
@import "a_brainstorm_bodytalk";
@import "a_myths";
@import "a_label_feelings";
@import "a_build_body";
@import "a_triggers_pong";
@import "a_behavior";
@import "emojis_personalizing";
@import "a_triggers";
@import "a_strategies";
/* goliotio ALSO CONTAINS:
		.a_let_it_out1_wrap,
		.a_let_it_out2_wrap,
		.a_think_it_out_intro,
		.a_let_it_out2_intro,
		.a_shapematch2_intro,
		.a_make_plan
*/
@import "goliotio";
@import "a_let_it_out";
@import "a_think_it_out";
@import "a_use_strats";
@import "a_scenarios";
/* a_make_plan includes a_s4_wrap */
@import "a_make_plan";