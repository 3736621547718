#avtr-holder{
	width: 100%;
	height: 100%;
	margin: 0 10.7%;
}

#avtr-holder *{
	margin-top: 70px;
	margin-right: 100px;
	background-repeat: no-repeat;
	background-size: contain;
	float: left;
	cursor: pointer;
}

#avtr-holder *:hover, #avtr-holder *:active, .choosen-avtr{
	  -webkit-box-shadow: 1px 1px 8px 6px rgba($lime-green,0.3) ;
	   box-shadow: 1px 1px 8px 6px rgba($lime-green,0.3) ;
}

#avatar-1{
	width: 165px;
    height: 440px;
    background-image: url(/images/avatar1.svg);
}
#avatar-2{
	width: 122px;
    height: 445px;
    background-image: url(/images/avatar2.svg);
}
#avatar-3{
	width: 150px;
    height: 440px;
  	background-image: url(/images/avatar3.svg);
}
#avatar-4{
	width: 200px;
    height: 445px;
  	background-image: url(/images/avatar4.svg);
}
.avtr{
	border-radius: 15px;
}

