.a_make_plan {
    .act-alert.emojis {
        width: 49em;
        margin-left: -23.5em;
        label[for="user-text"]::before {
            content: 'Other: Type in any feelings you may have that are not listed. Separate by comma.';
        }
    }
    .act-alert.triggers {
        label[for="user-text"]::before {
            content: 'Type in at least 2 possible triggers. Separate by comma.';
        }
    }
    .act-alert-txt {
        p {
            margin-bottom: 0;
        }
        h2 {
            margin-bottom: 0;
        }
        h3, h4 {
            margin-bottom: 0.4em;
        }
        .selected {
            color: $blue;
            text-transform: uppercase;
            background-color: lighten($light-blue, 5%);
            line-height: 2em;
        }
        .selected::before {
            content: '- ';
        }
        .selected::after {
            content: ' -';
        }
    }
    button[data-type="get-out"]::before {
        content: 'Help: GET OUT'
    }
    button[data-type="let-it-out"]::before {
        content: 'Help: LET IT OUT'
    }
    button[data-type="think-it-out"]::before {
        content: 'Help: THINK IT OUT'
    }
    .act-btn.help {
        float: right;
        font-size: 70%;
        margin-right: 1em;
    }
    .diagram {
        top: 76px;
        left: 45px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        .circle {
            height: 15em;
            width: 15em;
            border: thick solid #FFF;
            @include border-radius(50%);
            align-items: center;
            justify-content: center;
            display: flex;
            flex-flow: column;
            overflow: hidden;
            .words {
                font-family: $font-Audiowide;
                font-size: 1em;
                color: orange;
                text-transform: uppercase;
                width: 85%;
                margin-bottom: 0.15em;
            }
            .words.title {
                text-decoration: underline;
                font-size: 2em;
                color: #FFF;
            }
        }
        .arrow {
            height: 4em;
            width: 8em;
            background: no-repeat url(/images/arrow.svg);
            background-size: cover;
        }
    }
}

.a_make_plan,
.a_s4_wrap {
    #user-text,
    .user-text {
        margin: 0 auto;
        border: thin solid $blue;
        width: 94%;
    }
    label[for="user-text-go"],
    label[for="user-text-lio"],
    label[for="user-text-tio"],
    label[for="feedback-text"] {
        font-size: 100%;
        font-weight: 700;
        line-height: 1.5em;
        display: inline-block;
        text-align: left;
        width: 94%;
        margin-top: 0.6em;
    }

}