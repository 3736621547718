.a_use_strats,
.a_use_strats_intro {
    #stage-clipped {
        background-color: #000;
    }
    #debug-canvas {
        top: 78px;
        left: 46px;
        position: absolute;
    }
    .focus-overlay {
        width: 1190px;
        height: 568px;
        top: 78px;
        left: 46px;
        position: absolute;
        -webkit-clip-path: url(#canvas-clip);
        clip-path: url(#canvas-clip);
        opacity: 0;
        visibility: hidden;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#02ff13+51,02ff13+100&0+0,0+51,0.66+100 */
        background: -moz-radial-gradient(center, ellipse cover, rgba($lime-green, 0) 0%, rgba($lime-green, 0) 51%, rgba($lime-green, 0.66) 100%);
        /* FF3.6-15 */
        background: -webkit-radial-gradient(center, ellipse cover, rgba($lime-green, 0) 0%, rgba($lime-green, 0) 51%, rgba($lime-green, 0.66) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: radial-gradient(ellipse at center, rgba($lime-green, 0) 0%, rgba($lime-green, 0) 51%, rgba($lime-green, 0.66) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .powerbar-wrap {
        width: 350px;
        height: 14px;
        top: 155px;
        left: 50%;
        margin-left: -175px;
        position: absolute;
        .powerbar {
            width: 100%;
            height: 100%;
            margin-bottom: 0.6em;
            opacity: 0;
            visibility: hidden;
            text-align: left;
            border-left: thick solid #FFF;
            span {
                width: 100%;
                height: 100%;
                display: inline-block;
            }
        }
        .powerbar.blue {
            span {
                background-color: $light-blue;
            }
        }
        .powerbar.green {
            span {
                background-color: $lime-green;
            }
        }
    }
    .volume {
        color: $light-blue;
        font-family: "Audiowide", sans-serif;
        font-size: 3em;
        margin: 0.15em 0.6em;
        width: 2em;
        line-height: 0.8em;
        opacity: 0;
        display: flex;
        flex-direction: column;
        position: absolute;
        .vol {
            display: inline-block;
        }
        .vol::before {
            content: 'volume:';
            font-size: .45em;
            font-family: "Lato", sans-serif;
            color: $lime-green;
        }
    }
    .volume.right-side {
        /* calc( 100% - (width(2em) + margin-left(0.6em) + margin-right(0.6em))) */
        left: calc(100% - 3.2em);
    }
    .strat-btn {
        width: 5em;
        height: 5em;
        margin: 0.5em auto;
        @include border-radius(100%);
        border: 0.15em solid $lime-green;
        background-color: rgba(0,0,0,0.65);
        color: #FFF;
        font-family: "Audiowide", sans-serif;
        font-size: 1.1em;
        display: flex;
        justify-content: center;
    }
    .strat-btn.go::before {
        content: 'GET OUT'
    }
    .strat-btn.lio::before {
        content: 'LET IT OUT'
    }
    .strat-btn.tio::before {
        content: 'THINK IT OUT'
    }
    .strat-btn-wrap {
        margin-top: 7.4em;
        opacity: 0;
    }
    .act-question-box {
        h2 {
            text-align: center;
            width: 100%;
            margin: 0 0 0.5em;
        }
    }
    .guys-wrap {
        top: 0.8em;
        left: 65%;
        width: 450px;
        position: absolute;
        display: flex;
        flex-direction: row-reverse;
        justify-content: right;
        transform: scaleX(0.65) scaleY(0.65);
        .guy{
            background-image: url(/images/a_use_strats/a_use_strats_sprite.png);
            overflow: hidden;
            width: 150px;
            height: 101px;
            /* background-position: determined in code - ArtRegistry.js */
        }
    }
    .joy-note{
        top: 20rem;
        left: 22rem;
    }
    .feeling-note{
        top: 11rem;
        left: 1rem;
    }
    .strat-btn-alt {
        width: 10em;
        height: 10em;
        margin: 3em;
        @include border-radius(100%);
        border: 0.15em solid $lime-green;
        background-color: rgba(0,0,0,0.65);
        color: #FFF;
        font-family: "Audiowide", sans-serif;
        font-size: 1.1em;
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
    .strat-btn-activated {
        color: $lime-green;
    }
    .strat-btn-wrap-alt {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4.4em;
    }
    .strat-btn-wrap-alt-side {
        margin-top: 7.4em;
        pointer-events: none;
    }
    .strat-btn-alt.go::before {
        content: 'GET OUT'
    }
    .strat-btn-alt.lio::before {
        content: 'LET IT OUT'
    }
    .strat-btn-alt.tio::before {
        content: 'THINK IT OUT'
    }
}