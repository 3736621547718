.a_think_it_out {
    .bubble-holder,
    .act-note-div,
    .act-note {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .act-note-div {
        margin: 5em 4em 0;
        position: relative;
        cursor: pointer;
    }
    .act-note {
        max-width: 20em;
        height: 16em;
        @include border-radius(50%);
    }
    .act-note-text {
        font-size: 1.6em;
        color: $blue;
    }
    #alien {
        margin-top: 690px;
        width: 210px;
        height: 243px;
    }
    .scene-text {
        color: $lime-green;
        font-family: $font-PTSans;
        font-size: 1.7em;
        width: 60%;
        margin: 100px 15% 0;
        line-height: 1.4em;
        position: absolute;
        opacity: 0;
    }
    .bubble-holder {
        top: 4.9em;
        position: absolute;
        width: 1190px;
        height: 568px;
    }
    .blinker{
        animation: blinkTxt 1s linear infinite;
        @keyframes blinkTxt {
            50% {
                opacity: 0;
            }
        }
    }
    
}