.a_build_body {
    .act-alert-txt p {
        text-align: left;
    }
    .text-holder {
        width: 38rem;
        text-align: center;
        top: 9.7rem;
        left: 10rem;
        position: absolute;
        p{
            color: $lime-green;
            font-family: $font-PTSans;
            font-size: 1.3rem;
            line-height: 1.5rem;
        }
        span {
            color: #FFF;
            font-family: $font-Audiowide;
        }
    }
    .btn-holder {
        width: 8.1rem;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 2rem;
        h3 {
            color: $lime-green;
            font-family: $font-PTSans;
            margin-bottom: 0;
        }
        button {
            font-size:80%;
            margin: 0.2rem 0;
        }
        button[data-active="1"] {
            color: $blue;
            font-weight: 700;
            border-color: $lime-green;
            background-color: $lime-green;
        }
    }
    .btn-holder-wrap {
        height: 27rem;
        top: 11.9rem;
        left: 10rem;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: absolute;
    }
    #body-canvas {
        width: 320px;
        height: 530px;
        top: 95px;
        left: 800px;
        position: absolute;
        /* border: thin solid red; */
    }
    .bottom h2 {
        color: $lime-green;
        font-family: $font-PTSans;
        font-size: 1.6rem;
        margin-top: 3rem;
        display: none;
        .emph {
            color: #FFF;
            font-family: $font-Audiowide;
            text-transform: uppercase;
        }
    }
}
.holder.a_build_body {
    opacity: 0;
    visibility: hidden;
}